import { faqAquisicao } from "./faqAquisicao";
import { faqRentabilizacao } from "./faqRentabilizacao";

export const getFaq = (nomePlano, fluxo) => {
  let faq = null;
  if (fluxo !== "aquisicao") {
    faq = faqRentabilizacao;
  } else {
    faq = faqAquisicao;
  }
  const objPerguntas = Object.keys(faq).filter((key, index) => key?.toLocaleLowerCase() === nomePlano?.toLocaleLowerCase());
  if (!faq[objPerguntas]) {
    return faq.todos;
  }

  return faq[objPerguntas];
};
