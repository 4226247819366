import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { passoCarrinhoState } from "../atoms/passoCarrinhoAtom";
import { planoSelecionadoState } from "../atoms/planoSelecionadoAtom";
import { getFaq } from "../utils/faq";
import DuvidaPergunta from "./DuvidaPergunta";
import { processoState } from "../atoms/processAtom";

export default function Duvidas() {
  const [passosCarrinho] = useRecoilState(passoCarrinhoState);
  const [planoSelecionado] = useRecoilState(planoSelecionadoState);
  const [processo] = useRecoilState(processoState);
  const [corTexto, setCorTexto] = useState("secondary-tim");
  const [corBg, setCorBg] = useState("white");
  const [perguntas, setPerguntas] = useState([]);

  useEffect(() => {
    if (passosCarrinho >= 1) {
      setCorTexto("white");
      setCorBg("secondary-tim");
    } else {
      setCorTexto("secondary-tim");
      setCorBg("white");
    }

    setPerguntas(getFaq(planoSelecionado?.planoDestino, processo?.fluxo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passosCarrinho, processo]);
  return (
    <div className={`w-full bg-${corBg} text-${corTexto} `}>
      <div className={`px-4 py-8 pb-2 div-duvidas controle-tamanho-tela `}>
        <div>
          <span className={`px-4 py-2 font-bold border border-${corTexto} text-${corTexto} rounded-xl`}>Principais duvidas</span>
        </div>
        {/* Lista Duvidas */}
        <div className="pt-8 pb-8">
          {perguntas?.length > 0 &&
            perguntas.map((pergunta, index) => (
              <DuvidaPergunta
                key={"pergunta-" + index}
                corBg={corBg}
                corTexto={corTexto}
                pergunta={pergunta.pergunta}
                label={pergunta.label}
                postLabel={pergunta.postLabel}
                resposta={pergunta.resposta}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
