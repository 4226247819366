import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

export default function DuvidaPergunta({ id, pergunta, resposta, label, postLabel, corTexto, corBg }) {
  const [respostaVisivel, setRespostaVisivel] = useState(0);

  useEffect(() => {
    setRespostaVisivel(0);
  }, [pergunta]);

  const handleExibirResposta = () => {
    setRespostaVisivel(respostaVisivel === 0 ? 1 : 2);

    if (respostaVisivel === 0) {
      setTimeout(() => {
        setRespostaVisivel(2);
      }, 100);
    }
    if (respostaVisivel === 2) {
      setRespostaVisivel(1);
      setTimeout(() => {
        setRespostaVisivel(0);
      }, 600);
    }
  };
  return (
    <div className={`mb-3 border-b border-${corTexto} pb-2 bg-${corBg} text-${corTexto}`}>
      <div className="flex flex-row justify-between mb-2 cursor-pointer" onClick={handleExibirResposta}>
        <h3 className="text-sm font-bold">{pergunta}</h3>
        <span className="pr-1">{respostaVisivel > 0 ? <ChevronUpIcon className="w-6" /> : <ChevronDownIcon className="w-6" />}</span>
      </div>
      {respostaVisivel > 0 && (
        <div
          className={`overflow-y-hidden font-normal text-sm transition-height ease-in-out ${respostaVisivel === 2 ? " max-h-screen" : " max-h-0 "}`}
        >
          <p className="pl-2 md:pr-8 whitespace-pre-wrap">
            {typeof resposta === "string" && resposta}

            {(resposta.label || label) && `${resposta.label || label}`}
          </p>

          {resposta.table && <RespostaTabela tabela={resposta.table} />}

          {(resposta.list || Array.isArray(resposta)) && <RespostaLista respostas={resposta} />}

          <p className="pl-2 mt-3 md:pr-8 whitespace-pre-wrap">{(resposta.postLabel || postLabel) && `${resposta.postLabel || postLabel}`}</p>
        </div>
      )}
    </div>
  );
}

function RespostaTabela({ tabela }) {
  return (
    <div>
      {tabela.title && <h5 className="py-2 pl-2 font-medium">{tabela.title}</h5>}
      <table className="mx-auto mt-3 md:w-11/12 lg:w-8/12">
        <tbody>
          {tabela.rows?.map((linha, i) =>
            linha.head ? (
              <tr key={"linha-titulo-" + i}>
                {linha.cols?.map((coluna, index) => (
                  <td key={"coluna-th-" + index} className="px-2 font-bold">
                    {coluna}
                  </td>
                ))}
              </tr>
            ) : (
              <tr key={"linha-" + i} className="border-b border-gray-300 last:border-0">
                {linha.cols?.map((coluna, index) => (
                  <td key={"coluna-td-" + index} className="p-3 ">
                    {coluna}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

function RespostaLista({ respostas }) {
  const itemUnico = !Array.isArray(respostas);
  respostas = !itemUnico ? respostas : [respostas];

  return (
    <div>
      {respostas.map((itemResposta) => (
        <>
          {itemResposta.label && !itemUnico && <p className="font-medium mt-3 -mb-3 pl-2 md:pr-8 whitespace-pre-wrap">{itemResposta.label} </p>}
          {itemResposta.list?.numerada === true ? (
            <ol className="mx-6 mt-3">
              {itemResposta.list.items.map((item, index) => (
                <li className="list-decimal" key={"item-lista-" + index}>
                  {item}
                </li>
              ))}
            </ol>
          ) : (
            <ul className="mx-6 mt-3">
              {itemResposta.list?.items.map((item, index) => (
                <li className="list-disc" key={"item-lista-" + index}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </>
      ))}
    </div>
  );
}
