export const faqRentabilizacao = {
  "TIM Controle Redes Sociais": [
    {
      pergunta: "O que é o TIM Controle Redes Sociais?",
      resposta: {
        label:
          "TIM Controle Redes Sociais é uma das ofertas do plano TIM Controle. Confira os aplicativos inclusos na oferta e use os melhores serviços de forma ilimitada, sem gastar nada da sua internet:",
        list: {
          items: [
            "Apps de Comunicação: WhatsApp e Facebook Messenger para trocar mensagens de texto, áudios e, de sobra, compartilhar e receber fotos.",
            "Apps de Rede Sociais: Instagram, Facebook e X (antigo Twitter).",
            "Além disso, faça ligações ilimitadas para qualquer operadora do Brasil usando o 41 e acesse Apps exclusivos, como o Bancah Premium + Jornais.",
          ],
        },
      },
    },
    {
      pergunta: "Posso abrir um link externo sem gastar minha internet?",
      resposta:
        "Infelizmente, não. O acesso a sites ou a outros conteúdos externos ao App do parceiro vai descontar da sua franquia de internet, normalmente.",
    },
    {
      pergunta: "Por que no meu celular aparece o consumo dos aplicativos que não descontam da minha internet?",
      resposta:
        "Mesmo com a isenção do pagamento, o tráfego utilizado por cada App continuará sendo contabilizado no seu celular. Mas, pode ficar tranquilo, esse registro não significa que haverá qualquer tipo de cobrança pelo uso dos seus benefícios. Fique atento: se você usa algum aplicativo externo para controlar o uso de internet, a mesma coisa pode ocorrer: o tráfego dos Apps parceiros será contabilizado sem que, por isso, qualquer cobrança seja vinculada.",
    },
    {
      pergunta: "Como faço para pagar a mensalidade no cartão de crédito, sem mudar o meu número?",
      resposta: {
        label: "Você pode fazer isso de duas formas:",
        list: {
          items: [
            "1 - Acesse a página de TIM Controle e selecione para contratar a modalidade no cartão de crédito. Depois, é só colocar seu número TIM que, em seguida, faremos a migração para você!",
            "2 - Pelo App Meu TIM ou pelo Meu TIM Web, onde você gerencia todas as suas preferências e recebe o suporte mais completo para o seu plano.",
          ],
        },
      },
    },
    {
      pergunta: "Como vejo meu consumo de internet?",
      resposta: {
        label: "Você pode consultar seu consumo de internet de três formas:",
        list: {
          items: [
            "Enviar um SMS com a palavra WEB para 4141;",
            "Ligar *144# e escolher a opção de consulta de internet;",
            "Acessar o App Meu TIM ou o Meu TIM Web.",
          ],
        },
      },
    },
    {
      pergunta: "Quais assinaturas de serviços de conteúdo tenho na minha oferta?",
      resposta: {
        label: "Na oferta TIM Controle Redes Sociais, você pode usar os apps abaixo, além de tudo que já está incluído na sua oferta:",
        list: {
          items: ["Bancah Premium + Jornais;", "Aya Books;", "Aya Ensinah Premium;", "TIM Segurança Digital Light."],
        },
      },
    },
    {
      pergunta: "Como posso cancelar a minha oferta?",
      resposta: {
        label: "Você pode cancelar sua oferta de duas formas:",
        list: {
          items: [
            "1 - Ligue para o *144 e solicite o cancelamento da linha ou migração para o TIM Pré Top, isso pode ocorrer no momento da ligação ou no último dia de vencimento da oferta, lembrando que o pagamento é sempre efetuado antes do mês referente ao uso.",
            "2 - Pelo App Meu TIM ou pelo Meu TIM Web, onde você gerencia todas as suas preferências e recebe o suporte mais completo para o seu plano.",
          ],
        },
      },
    },
    {
      pergunta: "Posso mesmo fazer chamadas ilimitadas?",
      resposta:
        "Sim! O seu TIM Controle Redes Sociais oferece ligações ilimitadas para qualquer operadora e lugar do Brasil, basta que você utilize o 41!",
    },
    {
      pergunta: "O pacote de minutos poderá ser utilizado para quais tipos de ligações?",
      resposta: {
        label: "Para utilizar seu pacote de minutos, você pode fazer:",
        list: {
          items: [
            "Ligações de TIM para TIM;",
            "Ligações de TIM para outras operadoras do Brasil;",
            "Ligações para números fixos;",
            "Ligações para Nextel;",
            "Ligações locais;",
            "Ligações de longa distância (com 41).",
          ],
        },
      },
    },
    {
      pergunta: "Caso eu utilize todo o pacote de internet da minha oferta, o que acontece?",
      resposta:
        "Após o término da franquia, sua conexão de dados será bloqueada. Mas, não se preocupe: o uso dos apps inclusos no seu plano é ilimitado! \nSe você precisar de mais internet, ainda será possível contratar pacotes adicionais para continuar navegando.",
    },
    {
      pergunta: "Como posso contratar mais internet?",
      resposta:
        "Quando sua internet acabar, você receberá um SMS com um link, no qual poderá escolher entre adquirir mais 500MB, 1GB, 2GB ou 4GB de internet por 30 dias corridos, ou ativar a mecânica diária de cobrança até a renovação dos seus benefícios originais (50MB/dia). \nAlém disso, você também pode acessar o App Meu TIM ou o Meu TIM Web para contratar um pacote adicional de internet.",
    },
    {
      pergunta: "Quantas vezes posso contratar os pacotes adicionais de internet?",
      resposta:
        "Você pode adquirir pacotes adicionais de internet à vontade, não há um limite.\nContudo, não esqueça que você precisar esgotar completamente sua franquia ativa de internet antes de contratar o novo pacote.",
    },
    {
      pergunta:
        "Se eu não utilizar todo o pacote adicional de internet dentro dos 7 ou 30 dias corridos, poderei usar o restante após a data final da vigência?",
      resposta:
        "Infelizmente, não. Os pacotes adicionais de internet têm validade de 7 dias corridos (150MB), ou 30 dias corridos (500MB e 1GB). Caso você não utilize todo o pacote adicional dentro desse prazo, o volume restante não será acumulado.",
    },
    {
      pergunta: "Se o ciclo do meu plano renovar e eu ainda não tiver usado todo meu pacote adicional de internet, perderei a quantia restante?",
      resposta:
        "Não, não! O restante da internet do pacote adicional será utilizado antes do novo pacote de internet do seu plano. Então, não se preocupe!",
    },
    {
      pergunta: "Quando os benefícios do plano são renovados?",
      resposta: {
        label: "Os benefícios são renovados mensalmente, de acordo com a respectiva data de vencimento, conforme tabela abaixo:",
        table: {
          rows: [
            {
              head: true,
              cols: ["Data de Vencimento da Fatura", "Data de Renovação dos Benefícios"],
            },
            {
              cols: ["7", "14"],
            },
            {
              cols: ["10", "19"],
            },
            {
              cols: ["12", "19"],
            },
            {
              cols: ["15", "25"],
            },
            {
              cols: ["20", "1"],
            },
            {
              cols: ["25", "7"],
            },
          ],
        },
      },
    },
    {
      pergunta: "As fotos e vídeos recebidos no WhatsApp descontam do pacote de internet?",
      resposta:
        "As mensagens de texto, áudio, vídeo e foto enviadas e recebidas no WhatsApp e no Messenger não descontam do seu pacote de internet!\nMas, atenção: as chamadas de voz e vídeo realizadas e recebidas via WhatsApp/Messenger e o acesso a páginas fora do aplicativo, mesmo que os links tenham sido enviados internamente, não fazem parte dos benefícios e, por isso, implicarão em cobrança.",
    },
    {
      pergunta: "Postar e visualizar vídeos e fotos nos “Stories” do Instagram descontam do pacote de internet?",
      resposta:
        "Não descontam! Você pode postar e acompanhar os “stories” dos seus amigos sem preocupações, mesmo após o final da sua franquia principal de internet.\n" +
        "Além disso, você também pode postar fotos e vídeos no seu feed, bem como visualizar o feed dos seus amigos e mandar directs à vontade!\n\n" +
        "Atenção: realizar ou assistir transmissões de vídeos ao vivo, IGTV e bate-papos ao vivo, não estão entre os benefícios do plano.",
    },
    {
      pergunta: "Posso usar o Facebook à vontade, sem descontar da minha internet?",
      resposta:
        "Isso mesmo! Você pode realizar postagens, visualizar fotos, vídeos e textos, compartilhar “stories”, fazer e assistir transmissões ao vivo, mandar e receber mensagens e ligações de voz no Facebook Messenger e muito mais.\n" +
        "Tudo isso sem descontar nada da sua franquia de internet. Aproveite!",
    },
    {
      pergunta: "E a navegação no X (antigo Twitter), tá inclusa?",
      resposta:
        "Sim, com certeza! Você pode postar textos, fotos, vídeos e GIFs, além de mandar mensagens diretas para os seus amigos, tudo isso sem descontar nada da sua franquia de internet.",
    },
    {
      pergunta: "Migrei do TIM Pré Top para o TIM Controle. Posso levar meu saldo para o novo plano?",
      resposta:
        "Infelizmente, não. Caso você migre do TIM Pré Top com saldo de recarga ativo, seus créditos perderão a validade e um novo ciclo será iniciado no TIM Controle.",
    },
  ],

  "TIM Controle Smart": [
    {
      pergunta: "Cliente TIM Controle Smart tem ligações ilimitadas?",
      resposta:
        "Promocionalmente, Clientes TIM Controle Smart terão minutos ilimitados para ligar para números móveis e fixos de qualquer operadora (SMP e STFC) em ligações locais e de longa distância nacional com o código 41, na região de origem ou em roaming nacional.",
    },
    {
      pergunta: "O pacote de minutos (ilimitados) poderá ser utilizado para qual tipo de ligação?",
      resposta: {
        list: {
          items: [
            "Ligações de TIM para TIM;",
            "Ligações de TIM para outras operadoras do Brasil;",
            "Ligações para números fixos;",
            "Ligações para Nextel, exceto rádios;",
            "Ligações locais;",
            "Ligações de longa distância (com 41).",
          ],
        },
      },
    },
    {
      pergunta: "Ligações a cobrar estão incluídas no pacote de minutos do TIM Controle Smart?",
      resposta:
        "Não. Ligações a cobrar não estão incluídas no pacote de minutos da oferta TIM Controle Smart  e será cobrada separadamente em R$0,25 por minuto. ",
    },
    {
      pergunta: "Se eu não usar todo o pacote de internet (5GB) da oferta dentro do prazo, o resto acumula para o mês seguinte?",
      resposta: "Não. Dados remanescentes do pacote de internet não são acumulativos para o mês seguinte.",
    },
    {
      pergunta: "Se eu não usar todo o pacote de internet (5GB) da oferta dentro do prazo, o que acontece?",
      resposta: "Infelizmente, o volume restante não será acumulado para o mês seguinte. Portanto, o saldo será perdido.",
    },
    {
      pergunta: "Como contratar pacotes adicionais de internet para continuar navegando?",
      resposta:
        "Quando sua internet acabar, você receberá um SMS com um link, no qual poderá escolher entre adquirir mais 500MB, 1GB, 2GB ou 4GB de internet por 30 dias corridos, ou ativar a mecânica diária de cobrança até a renovação dos seus benefícios originais (50MB/dia).\n" +
        "Além disso, você também pode acessar o App Meu TIM ou o Meu TIM Web para contratar um pacote adicional de internet.",
    },
    {
      pergunta: "Quantas vezes é possível contratar pacotes adicionais de internet?",
      resposta:
        "Você pode adquirir pacotes adicionais de internet à vontade, não há um limite.\n" +
        "Contudo, não esqueça que você precisar esgotar completamente sua franquia ativa de internet antes de contratar o novo pacote.",
    },
    {
      pergunta: "Se eu não utilizar todo o pacote adicional de internet dentro do prazo, poderei usar o restante após o final da vigência?",
      resposta: "Infelizmente, não. Caso você não utilize todo o pacote adicional dentro do prazo, o volume restante não será acumulado.",
    },
    {
      pergunta: "Como o Cliente pode consultar o consumo do pacote de internet?",
      resposta: {
        label: "O Cliente poderá consultar o volume consumido de seu pacote de internet através dos seguintes canais:",
        list: {
          items: ["Meu TIM", "Ligação: *144", "SMS: enviando “WEB” para 4141."],
        },
      },
    },
    {
      pergunta: "Quando os benefícios do plano são renovados?",
      resposta: {
        label: "Os benefícios são renovados mensalmente, de acordo com a respectiva data de vencimento, conforme tabela abaixo:",
        table: {
          title: "CONTROLE FATURA",
          rows: [
            {
              head: true,
              cols: ["Data de Vencimento da Fatura", "Data de Renovação dos Benefícios"],
            },
            {
              cols: ["7", "14"],
            },
            {
              cols: ["10", "19"],
            },
            {
              cols: ["12", "19"],
            },
            {
              cols: ["15", "25"],
            },
            {
              cols: ["20", "1"],
            },
            {
              cols: ["25", "7"],
            },
          ],
        },
      },
    },
    {
      pergunta: "A utilização do WhatsApp e Messenger é ilimitada, sem desconto no pacote de internet?",
      resposta:
        "As mensagens de texto, áudio, vídeo e foto enviadas e recebidas no WhatsApp e Messenger não descontam do seu pacote de internet!\n" +
        "Mas, atenção: as chamadas de voz e vídeo realizadas e recebidas via WhatsApp/Messenger e o acesso a páginas fora do aplicativo, mesmo que os links tenham sido enviados internamente, não fazem parte dos benefícios e, por isso, implicarão em cobrança.",
    },
    {
      pergunta: "A utilização do Instagram, Facebook e X (antigo Twitter) é ilimitada nos primeiros 3 meses, sem desconto no pacote de internet?",
      resposta:
        "Durante os primeiros 3 meses após a adesão da oferta, os clientes TIM Controle Smart poderão utilizar os aplicativos Instagram, Facebook e X (antigo Twitter) sem qualquer desconto do pacote de internet contratado.\n" +
        "No Instagram, você pode postar e visualizar fotos, vídeos e “stories”, além de enviar e receber mensagens pelo Direct. Infelizmente, os benefícios não incluem a transmissão de vídeos ao vivo.\n" +
        "Da mesma forma, qualquer utilização da internet fora do App, mesmo que o link tenha sido enviado internamente, irá descontar do seu pacote de dados.",
    },
    {
      pergunta: "Quais assinaturas de serviços estão disponíveis na oferta TIM Controle Smart?",
      resposta: {
        label: "Na oferta TIM Controle Smart Fatura, você pode usar os apps abaixo, além de tudo que já está incluído na sua oferta:",
        list: {
          items: ["Bancah Jornais II;", "Aya Books", "Aya Ensinah Premium;", "TIM Segurança Digital Light."],
        },
      },
    },
    {
      pergunta: "Como o Cliente poderá cancelar o TIM Controle Smart?",
      resposta:
        "O Cliente deverá ligar para o *144 e solicitar o cancelamento da linha. A linha será migrada para o pré-pago. A migração poderá ocorrer no momento da ligação ou ser agendado para o último dia de vencimento da oferta, lembrando que o Cliente já efetuou o pagamento antecipado para o mês",
    },
    {
      pergunta: "Migrei do TIM Pré Top para o TIM Controle. Posso levar meu saldo para o novo plano?",
      resposta:
        "Infelizmente, não. Caso você migre do TIM Pré Top com saldo de recarga ativo, seus créditos perderão a validade e um novo ciclo será iniciado no TIM Controle.",
    },
    {
      pergunta:
        "No caso de um Cliente Pré-Pago que migrou para o TIM Controle Smart, caso ele tenha saldo de recarga do plano anterior do Pré-Pago, será possível levá-lo para o Controle?",
      resposta:
        "Não. Caso o Cliente venha de uma migração do Pré-pago com saldo de recarga ativo, seus créditos não serão mais válidos e ele começará um novo ciclo no TIM Controle.",
    },
    {
      pergunta: "Como posso cancelar a minha oferta?",
      resposta: {
        label: "Você pode cancelar sua oferta de duas formas:",
        list: {
          items: [
            "1 - Ligue para o *144 e solicite o cancelamento da linha ou migração para o TIM Pré Top, isso pode ocorrer no momento da ligação ou no último dia de vencimento da oferta, lembrando que o pagamento é sempre efetuado antes do mês referente ao uso.",
            "2 - Pelo App Meu TIM ou pelo Meu TIM Web, onde você gerencia todas as suas preferências e recebe o suporte mais completo para o seu plano.",
          ],
        },
      },
    },
  ],
  "TIM Controle A Plus": [
    {
      pergunta: "Cliente TIM Controle A Plus tem ligações ilimitadas?",
      resposta:
        "Clientes TIM Controle A Plus terão uma franquia de 550 minutos para ligar para números móveis e fixos de qualquer operadora (SMP e STFC) em ligações locais e de longa distância nacional com o código 41, na região de origem ou em roaming nacional.",
    },
    {
      pergunta: "O pacote de minutos (550 minutos) poderá ser utilizado para qual tipo de ligação?",
      resposta: {
        label: "Para utilizar seu pacote de minutos, você pode fazer:",
        list: {
          items: [
            "Ligações de TIM para TIM;",
            "Ligações de TIM para outras operadoras do Brasil;",
            "Ligações para números fixos;",
            "Ligações para Nextel (exceto rádios);",
            "Ligações locais;",
            "Ligações de longa distância (com 41).",
          ],
        },
      },
    },
    {
      pergunta: "Caso o pacote de minutos (550 minutos) acabe, como o Cliente deve proceder?",
      resposta:
        "Clientes da oferta TIM Controle A Plus serão avisados através de SMS ao atingir 80% do pacote de minutos. No momento em que o Cliente consumir 100% do seu pacote, receberá um SMS com esta informação e um link gratuito, no qual poderá escolher contratar um novo pacote de voz semanal descontando sempre de seus créditos das recargas adicionais válidos.\n" +
        "\nImportante: No momento que o Cliente atingir 100% da sua franquia de voz, caso não faça contratação de um novo pacote de voz, será descontado automaticamente dos seus créditos das recargas adicionais válidas sempre que fizer qualquer ligação.",
    },
    {
      pergunta: "Quantas vezes é possível contratar pacotes adicionais de minutos?",
      resposta:
        "Você pode adquirir pacotes adicionais de minutos à vontade, não há um limite.\n" +
        "\nContudo, não esqueça que você precisar esgotar completamente sua franquia ativa de minutos antes de contratar o novo pacote.",
    },
    {
      pergunta: "Se eu não utilizar todo o pacote adicional de minutos dentro dos 7 dias, poderei usar o restante após o final da vigência? ",
      resposta:
        "Não. Os pacotes adicionais (minutos) têm validade de 7 dias corridos. Caso o Cliente não utilize todo o pacote adicional dentro desse prazo, o volume (de MB ou minutos) remanescente não será acumulado.",
    },
    {
      pergunta:
        "O que acontece se o Cliente contratar o pacote adicional de minutos e, antes dos 7 dias corridos, o ciclo de cobrança renovar e ele ganhar todos os benefícios das ofertas novamente? O Cliente perderá os minutos restantes do pacote adicional?",
      resposta:
        "Não. Caso a validade do pacote adicional de minutos ultrapasse a data de renovação dos benefícios da Oferta TIM Controle A Plus, os minutos restantes do pacote adicional serão utilizados prioritariamente ao pacote de minutos renovado das ofertas (550 minutos).",
    },
    {
      pergunta: "Ligações a cobrar estão incluídas no pacote de minutos do TIM Controle A Plus?",
      resposta:
        "Não. Ligações a cobrar não estão incluídas no pacote de minutos da oferta TIM Controle A Plus e serão cobradas separadamente no valor de R$0,25 por minuto.",
    },
    {
      pergunta: "Se eu não usar todo o pacote de internet (3.5GB) da oferta dentro do prazo, o que acontece?",
      resposta: "Infelizmente, o volume restante não será acumulado para o mês seguinte. Portanto, o saldo será perdido.",
    },
    {
      pergunta: "Caso eu utilize todo o pacote de internet da minha oferta, o que acontece?",
      resposta:
        "Após o término da franquia, sua conexão de dados será bloqueada. Mas, não se preocupe: o uso dos apps inclusos no seu plano é ilimitado!\n" +
        "\nSe você precisar de mais internet, ainda será possível contratar pacotes adicionais para continuar navegando.",
    },
    {
      pergunta: "Como contratar pacotes adicionais de internet para continuar navegando?",
      resposta:
        "Quando sua internet acabar, você receberá um SMS com um link, no qual poderá escolher entre adquirir mais 500MB, 1GB, 2GB ou 4GB de internet por 30 dias corridos, ou ativar a mecânica diária de cobrança até a renovação dos seus benefícios originais (50MB/dia).\n" +
        "\nAlém disso, você também pode acessar o App Meu TIM ou o Meu TIM Web para contratar um pacote adicional de internet.",
    },
    {
      pergunta: "Quantas vezes é possível contratar pacotes adicionais de internet?",
      resposta:
        "Você pode adquirir pacotes adicionais de internet à vontade, não há um limite.\n" +
        "Contudo, não esqueça que você precisar esgotar completamente sua franquia ativa de internet antes de contratar o novo pacote.",
    },
    {
      pergunta:
        "O que acontece se o Cliente contratar o pacote adicional de internet e, antes dos 30 dias corridos, o ciclo de cobrança se renovar retomando todos os benefícios da oferta novamente? O Cliente perderá os MB restantes do pacote adicional de internet?",
      resposta:
        "Não. Caso a validade do pacote adicional de internet ultrapasse a data de renovação dos benefícios da oferta TIM Controle A Plus, os MB restantes do pacote adicional serão utilizados prioritariamente ao pacote de internet renovado (3,5GB).",
    },
    {
      pergunta: "Como eu posso consultar meu consumo do pacote de minutos?",
      resposta: {
        label: "Existem algumas formas de você consultar o consumo do seu pacote em minutos:",
        list: {
          items: ["Pelo App Meu TIM ou Meu TIM Web;", "Pelo canal de atendimento telefônico *144;", "Via SMS, enviando “WEB” para 4141."],
        },
      },
    },
    {
      pergunta: "Quando os benefícios do plano são renovados?",
      resposta: {
        label: "Os benefícios são renovados mensalmente, de acordo com a respectiva data de vencimento, conforme tabela abaixo:",
        table: {
          title: "CONTROLE FATURA",
          rows: [
            {
              head: true,
              cols: ["Data de Vencimento da Fatura", "Data de Renovação dos Benefícios"],
            },
            {
              cols: ["7", "14"],
            },
            {
              cols: ["10", "19"],
            },
            {
              cols: ["12", "19"],
            },
            {
              cols: ["15", "25"],
            },
            {
              cols: ["20", "1"],
            },
            {
              cols: ["25", "7"],
            },
          ],
        },
      },
    },
    {
      pergunta: "A utilização do Whatsapp e Messenger é ilimitada, sem desconto no pacote de internet?",
      resposta:
        "As mensagens de texto, áudio, vídeo e foto enviadas e recebidas no WhatsApp e Messenger não descontam do seu pacote de internet!\n" +
        "\nMas, atenção: as chamadas de voz e vídeo realizadas e recebidas via WhatsApp/Messenger e o acesso a páginas fora do aplicativo, mesmo que os links tenham sido enviados internamente, não fazem parte dos benefícios e, por isso, implicarão em cobrança.",
    },
    {
      pergunta: "Quais assinaturas de serviços estão disponíveis na oferta?",
      resposta: {
        label: "Na oferta TIM Controle A Plus, você poderá usar os apps abaixo, além de tudo que já está incluído na oferta",
        list: {
          items: ["Aya Books Light", "Aya Ensinah Premium;", "TIM Segurança Digital Light."],
        },
      },
    },
    {
      pergunta:
        "No caso de um Cliente Pré-Pago que migrou para o TIM Controle A Plus, caso ele tenha saldo de recarga do plano anterior do Pré-Pago, será possível levá-lo para o Controle?",
      resposta:
        "Não. Caso o Cliente venha de uma migração do Pré-pago com saldo de recarga ativo, seus créditos não serão mais válidos e ele começará um novo ciclo no TIM Controle.",
    },
    {
      pergunta: "Como o Cliente poderá cancelar o TIM Controle A Plus?",
      resposta:
        "O Cliente deverá ligar para o *144 e solicitar o cancelamento da linha. A linha será migrada para o pré-pago. A migração poderá ocorrer no momento da ligação ou ser agendado para o último dia de vencimento da oferta, lembrando que o Cliente já efetuou o pagamento antecipado para o mês.",
    },
  ],

  todos: [
    {
      pergunta: "O que é o plano Controle?",
      resposta: {
        label:
          "O plano Controle é um plano móvel mensal, de valor e benefícios fixos durante o período. Com o TIM Controle, você aproveita a melhor cobertura para fazer ligações e navegar à vontade pela internet, contratando uma das ofertas a seguir:",
        list: {
          items: ["TIM Controle Redes Sociais 5.0;", "TIM Controle Smart 5.0;", "TIM Controle B Express 5.1;", "TIM Controle C Express 5.1"],
        },
      },
    },
    {
      pergunta: "Qual a vantagem do plano Controle?",
      resposta: {
        label:
          "O plano Controle conta uma variedade de ofertas, cada uma delas com um conjunto específico de benefícios.\n" +
          "De modo geral, o TIM Controle oferece:",
        list: {
          items: [
            "Pacotes de internet para usar como quiser;",
            "Apps de comunicação, como WhatsApp, Messenger e Telegram;",
            "Redes sociais, como Instagram, Facebook e X;",
            "Ligações ilimitadas para qualquer operadora;",
            "Franquia de SMS;",
            "Apps com serviços adicionais.",
          ],
        },
      },
    },
    {
      pergunta: "Qual a diferença entre um plano pós-pago e plano Controle?",
      resposta:
        "O TIM Controle é um plano móvel mensal com benefícios fixos durante o período. Portanto, se os benefícios acabarem antes do prazo de validade da oferta, você precisará contratar pacotes adicionais separadamente ou aguardar a renovação automática.\n" +
        "\nAinda assim, o pagamento da fatura funciona da mesma forma que nos demais planos móveis da TIM, com excessão do plano TIM Pré Top.\n" +
        "\nO plano pós-pago TIM Black, por sua vez, tem ofertas que disponibilizam grandes quantidades de internet móvel e uma enorme variedade de benefícios inclusos para cada cliente montar seu pacote como preferir. Perfeito para quem viaja muito e precisa se manter conectado, no TIM Black é possível adicionar internet, minutos e outras vantagens a qualquer momento.",
    },
    {
      pergunta: "Qual a diferença entre os planos Controle da TIM?",
      label: "Existem quatro ofertas do plano Controle da TIM. Confira:",
      resposta: [
        {
          label: "TIM Controle B Express 5.1:",
          list: {
            items: [
              "5GB do plano + bônus de internet, com pagamento em cartão de crédito;",
              "3 meses de Instagram, Facebook e X (antigo Twitter) inclusos, sem gastar internet;",
              "WhatsApp e Messenger inclusos, sem gastar internet;",
              "Roaming Nacional;",
              "Ligações e SMS ilimitados e mais.",
            ],
          },
        },
        {
          label: "TIM Controle Smart 5.0:",
          list: {
            items: [
              "5GB do plano + bônus de internet, com pagamento na fatura;",
              "Possibilidade de assinatura do streaming de música Deezer Premium;",
              "3 meses de Instagram, Facebook e X (antigo Twitter) inclusos, sem gastar internet;",
              "WhatsApp e Messenger inclusos, sem gastar internet;",
              "Roaming Nacional;",
              "Ligações e SMS ilimitados e mais.",
            ],
          },
        },
        {
          label: "TIM Controle C Express 5.1:",
          list: {
            items: [
              "6GB do plano + bônus de internet, com pagamento em cartão de crédito;",
              "WhatsApp, Instagram, Facebook, Messenger e X (antigo Twitter) inclusos, sem gastar internet",
              "Roaming Nacional;",
              "Ligações e SMS ilimitados e mais.",
            ],
          },
        },
        {
          label: "TIM Controle Redes Sociais 5.0:",
          list: {
            items: [
              "6GB do plano + bônus de internet, com pagamento na fatura;",
              "Possibilidade de assinatura do streaming de música Deezer Premium;",
              "WhatsApp, Instagram, Facebook, Messenger e X (antigo Twitter) inclusos, sem gastar internet;",
              "Roaming Nacional;",
              "Ligações e SMS ilimitados e mais.",
            ],
          },
        },
      ],
    },

    {
      pergunta: "Quais os streamings e redes sociais tenho acesso com o plano Controle?",
      label:
        "Os streamings e redes sociais inclusos no plano TIM Controle variam de acordo com a oferta escolhida. Mas, em todas elas, você pode contratar o YouTube Premium pela TIM Store e aproveitar 2 meses grátis de vídeos e conteúdos sem anúncios.\n" +
        "\nConfira outros detalhes de streamings e apps de cada oferta do plano Controle:\n",
      resposta: [
        {
          label: "TIM Controle B Express 5.1:",
          list: {
            items: [
              "3 meses de Instagram, Facebook e X (antigo Twitter) inclusos, sem gastar internet;",
              "WhatsApp e Messenger inclusos, sem gastar internet;",
            ],
          },
        },
        {
          label: "TIM Controle Smart 5.0:",
          list: {
            items: [
              "3 meses de Instagram, Facebook e X (antigo Twitter) inclusos, sem gastar internet;",
              "WhatsApp e Messenger inclusos, sem gastar internet;",
              "Possibilidade de assinatura do streaming de música Deezer Premium.",
            ],
          },
        },
        {
          label: "TIM Controle C Express 5.1:",
          list: {
            items: ["WhatsApp, Instagram, Facebook, Messenger e X (antigo Twitter) inclusos, sem gastar internet."],
          },
        },
        {
          label: "TIM Controle Redes Sociais 5.0:",
          list: {
            items: [
              "WhatsApp, Instagram, Facebook, Messenger e X (antigo Twitter) inclusos, sem gastar internet;",
              "Possibilidade de assinatura do streaming de música Deezer Premium.",
            ],
          },
        },
      ],
    },

    {
      pergunta: "O meu TIM Controle dá descontos em smartphones?",
      resposta:
        "Sim, algumas ofertas do TIM Controle oferecem descontos em smartphones de diversas marcas!\n" +
        "Confira todos os detalhes na nossa Loja Online.",
    },

    {
      pergunta: "Como eu posso pagar o meu TIM Controle?",
      label: "Você pode pagar sua fatura TIM Controle de diversos modos.",
      resposta: {
        list: {
          items: ["PIX;", "Cartão de crédito;", "Débito automático;", "Rede Celcoin;", "PicPay;", "Recarga Pay;", "Código de barras;", "C6 Bank."],
        },
      },
      postLabel:
        "É possível efetuar o pagamento diretamente com o cartão de crédito do C6 Bank.\n" +
        "Seja qual for sua escolha, seu bônus de 4GB de internet já está garantido!",
    },
  ],
};
